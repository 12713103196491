@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-cool-gray-900 bg-white dark:bg-gray-800 dark:text-gray-400;
  }

  #root {
    @apply min-h-screen;
    @apply flex flex-col;
  }

  a {
    @apply underline;
  }

  h2 {
    @apply text-2xl font-bold;
  }
}
